/* eslint-disable react/button-has-type */
import React, { useState, useCallback, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { actions as categoriesActions } from '../../../store/ducks/categories.duck';
import { actions as productCatalogActions } from '../../../store/ducks/product-catalog.duck';
import { ReactComponent as Menu } from '../../../../_metronic/layout/assets/layout-svg-icons/menu4.svg';
import { ReactComponent as MenuActive } from '../../../../_metronic/layout/assets/layout-svg-icons/menu_active4.svg';
import SearchField from '../SearchField';
import { IAppState } from '../../../store/rootDuck';
import DialogCategories from '../DialogCategories';
import { toAbsoluteUrl } from '../../../../_metronic';

const HeaderMobileNavigation = ({ isSubHeader = false }: { isSubHeader: boolean }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(540));
  const { catalogCategories } = useSelector((store: IAppState) => ({
    catalogCategories: store.categories.catalogCategories,
  }));

  const { filter, loading } = useSelector((store: IAppState) => ({
    filter: store.productsCatalog.filter,
    loading: store.productsCatalog.loading,
  }));
  const [open, setOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);

  // useLayoutEffect(() => {
  //   dispatch(categoriesActions.fetchCatalogCategories({with_products: false}));
  // }, []);

  const clear = () => {
    resetProducts();
    setSelectedCategoryId(null);
  };

  const resetProducts = useCallback(() => {
    dispatch(productCatalogActions.fetchRequest({ page: 1, perPage: 24, filter }));
  }, [catalogCategories]);

  return (
    <div style={{ width: '100%', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
          {open ? <MenuActive /> : <Menu />}
        </div>
        {!isSmallScreen && (
          <Link to='/products/catalog' style={{ marginLeft: 20 }}>
            <img alt='logo' src={toAbsoluteUrl('/media/logos/logo-opt1.png')} height={40} />
          </Link>
        )}
        <SearchField clear={clear} />
      </div>
      <DialogCategories
        setSelectedCategoryId={setSelectedCategoryId}
        selectedCategoryId={selectedCategoryId}
        open={open}
        close={() => setOpen(false)}
        clear={clear}
        setSearch={value => dispatch(productCatalogActions.setSearch(value))}
      />
    </div>
  );
};

export default HeaderMobileNavigation;
