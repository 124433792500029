import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, CircularProgress } from '@material-ui/core';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { useSnackbar } from 'notistack';
import { getPublicDoc } from '../../../crud/docs.crud';

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  contentType: string;
  title?: string;
}

const TermDialog: React.FC<IProps & WrappedComponentProps> = ({
  isOpen,
  handleClose,
  contentType,
  intl,
  title,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (contentType !== '') {
      getPublicDoc(contentType)
        .then(res => {
          setContent(res.data.data.text);
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
          enqueueSnackbar(err.message, {
            variant: 'error',
          });
        });
    }
  }, [contentType]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='md'
      fullWidth
    >
      <DialogTitle id='alert-dialog-title'>
        {title ||
          intl.formatMessage({
            id:
              contentType === 'user_agreement'
                ? 'AUTH.GENERAL.LEGAL'
                : 'SUBMENU.LEGAL_PRIVACY',
          })}
      </DialogTitle>
      <DialogContent
        style={{
          // maxWidth: 450,
          height: '100%',
          position: 'relative',
        }}
      >
        {loading ? (
          <div style={loaderWrapStyle}>
            <CircularProgress size={20} />
          </div>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: content }} style={{ color: 'black' }} />
        )}
      </DialogContent>
    </Dialog>
  );
};

const loaderWrapStyle = {
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export default injectIntl(TermDialog);
