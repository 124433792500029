import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, TextField, InputAdornment, Box } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { actions as productCatalogActions } from '../../../store/ducks/product-catalog.duck';
import { ReactComponent as Clear } from '../../../../_metronic/layout/assets/layout-svg-icons/clear-icon.svg';
import { ReactComponent as Search } from '../../../../_metronic/layout/assets/layout-svg-icons/search-icon.svg';
import { ReactComponent as SearchBlack } from '../../../../_metronic/layout/assets/layout-svg-icons/search-black-icon.svg';
import { useSearchList } from './hooks/useSearchList';
import { useStylesSearch } from './hooks/useStyles';
// import { ReactComponent as Home } from '../../../../_metronic/layout/assets/layout-svg-icons/home.svg';
// import { ReactComponent as HomeActive } from '../../../../_metronic/layout/assets/layout-svg-icons/home_active.svg';

const SearchField = ({
  isSubHeader = false,
}: // clear,
{
  isSubHeader?: boolean;
  clear?: () => void;
}) => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStylesSearch();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [search, setSearchText] = useState<string>('');
  const { page, perPage, filter, isSearching } = useSelector(
    ({ productsCatalog }: any) => productsCatalog
  );
  const { fetchRequest, setSearch } = productCatalogActions;

  const { fetchSearch, loadingSearch, productsSearch, clearSearch } = useSearchList();

  useEffect(() => {
    isSearching === false && setSearchText('');
  }, [isSearching]);

  const searchProducts = useCallback(
    (page: any, perPage: any, filter: any, search: string, isRedirect?: any) => {
      let searchStatus;
      if (search === '') searchStatus = false;
      else searchStatus = true;
      dispatch(setSearch(searchStatus));
      dispatch(fetchRequest({ page, perPage, filter, search }));
      if (isRedirect) {
        if (history.location.pathname !== '/products/catalog') {
          history.push('/products/catalog/');
        }
      }
    },
    []
  );

  return (
    <form
      className={isSubHeader ? classes.textFieldWrapper : classes.textFieldWrapperSubHeader}
      onSubmit={e => {
        e.preventDefault();
        searchProducts(page, perPage, filter, search, true);
      }}
    >
      <div className={classes.wrapperSearch}>
        <Autocomplete
          clearOnBlur={false}
          options={productsSearch}
          onChange={(_event, newValue) => {
            setSearchText(newValue ? newValue.name : '');
            if (newValue) {
              searchProducts(page, perPage, filter, newValue.name, true); // Trigger search on selection
            }
          }}
          onInputChange={(_event, newInputValue) => {
            setSearchText(newInputValue);
            newInputValue ? fetchSearch(newInputValue) : clearSearch();
          }}
          value={{ id: 0, name: search }}
          inputValue={search}
          style={{ width: '100%' }}
          noOptionsText={intl.formatMessage({ id: 'CATEGORIES.TABLE.EMPTY' })}
          loadingText={intl.formatMessage({ id: 'COMMON.LOADING' })}
          disableClearable
          getOptionLabel={option => option.name}
          loading={loadingSearch}
          // disablePortal
          renderOption={option => (
            <>
              <SearchBlack style={{ marginRight: 5 }} height={12} width={12} />
              <li className={classes.listItem}>{option.name}</li>
            </>
          )}
          renderInput={params => (
            <TextField
              {...params}
              variant='outlined'
              size='small'
              className={classes.textField}
              label={intl.formatMessage({ id: 'PRODUCTS.FIND' })}
              value={search}
              InputProps={{
                ...params.InputProps,
                style: {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  height: 34,
                },
                endAdornment: (
                  <InputAdornment position='end' style={{ paddingBottom: 6, }}>
                    <span
                      className={classes.clear}
                      onClick={() => {
                        setSearchText('');
                        clearSearch();
                        searchProducts(page, perPage, filter, '');
                      }}
                    >
                      {search !== '' &&
                        (isSubHeader ? (
                          intl.formatMessage({ id: 'PRODUCT.FILTER.RESET' })
                        ) : (
                          <Clear />
                        ))}
                    </span>
                    {params.InputProps.endAdornment}
                  </InputAdornment>
                ),
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                ...params.inputProps,
                // style: { fontFamily: 'Open Sans' },
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
            />
          )}
        />
        <Button
          disableElevation
          className={isSubHeader ? classes.isSubheader : classes.isNoneSubheader}
          onClick={() => searchProducts(page, perPage, filter, search, true)}
          color='primary'
          variant='contained'
          size='large'
        >
          {isSubHeader ? intl.formatMessage({ id: 'PRODUCT.FILTER.SEARCH' }) : <Search />}
        </Button>
      </div>
      {/* {!isSubHeader && ( */}
      {/*   <Link */}
      {/*     className={classes.home} */}
      {/*     to='/products/catalog' */}
      {/*     onClick={() => { */}
      {/*       clear && clear(); */}
      {/*     }} */}
      {/*   > */}
      {/*     {location.pathname === '/products/catalog' ? <HomeActive /> : <Home />} */}
      {/*     <div */}
      {/*       className={`kt-menu__link-text  ${classes.homeText}`} */}
      {/*       style={{ */}
      {/*         fontWeight: 700, */}
      {/*         color: location.pathname === '/products/catalog' ? '#78DC78' : '#005A5A', */}
      {/*       }} */}
      {/*     > */}
      {/*       {intl.formatMessage({ id: 'MENU.DASHBOARD' })} */}
      {/*     </div> */}
      {/*   </Link> */}
      {/* )} */}
    </form>
  );
};

export default SearchField;
