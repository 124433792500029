import React from 'react';

const InfoIcon: React.FC = () => {
  return (
    <>
      <svg width='25' height='25' viewBox='0 0 25 25' xmlns='http://www.w3.org/2000/svg'>
        <g fill='none' fill-rule='evenodd'>
          <rect
            stroke='#D01C1F'
            stroke-width='2'
            x='1'
            y='1'
            width='23'
            height='23'
            rx='11.5'
          />
          <path
            d='M14 16.508c0 .414-.145.767-.435 1.057-.29.29-.645.435-1.065.435-.42 0-.775-.145-1.065-.435A1.44 1.44 0 0 1 11 16.508c0-.425.145-.783.435-1.073.29-.29.645-.435 1.065-.435.42 0 .775.145 1.065.435.29.29.435.648.435 1.073zM13.106 14h-1.212a29.742 29.742 0 0 0-.12-.665c-.059-.31-.12-.669-.184-1.074-.063-.406-.13-.825-.199-1.259a129.746 129.746 0 0 1-.335-2.234A5.296 5.296 0 0 1 11 8.244c0-.358.146-.655.439-.89C11.73 7.118 12.085 7 12.5 7c.415 0 .769.118 1.061.354.293.235.439.532.439.89 0 .076-.019.25-.056.524a129.746 129.746 0 0 1-.335 2.234c-.07.434-.136.853-.2 1.259-.063.405-.122.763-.175 1.074-.053.311-.096.533-.128.665z'
            fill='#D01C1F'
            fill-rule='nonzero'
          />
        </g>
      </svg>
    </>
  );
};

export default InfoIcon;
