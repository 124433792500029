import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Autocomplete } from '@material-ui/lab';
import { Box, TextField } from '@material-ui/core';
import { ICountry } from '../../../pages/auth/interfaces';
import { defaultCountry } from '../../../pages/auth/hooks/useHandleCountrySelect';

type TProps = {
  countries: ICountry[];
  countryId: number;
  setCountryId: (id: number) => void;
  countriesLoading: boolean;
  showCode?: boolean;
  label?: string;
  variant?: 'outlined' | 'filled' | 'standard';
  disabled?: boolean;
};

const allowedCountries = [
  'Турция',
  'Киргизия',
  'Китай',
  'Вьетнам',
  'Узбекистан',
  'Таджикистан',
  'Россия',
];

const CountrySelect: React.FC<TProps> = ({
  countries,
  countryId,
  setCountryId,
  countriesLoading,
  showCode,
  variant = 'outlined',
  label,
  disabled,
}) => {
  const intl = useIntl();
  const filterCountries = useMemo(() => {
    if (countries) {
      const newList: ICountry[] = [];
      countries.forEach(i => {
        if (allowedCountries.includes(i.ru_name)) {
          if (i.id === 1 || i.id === 76) {
            newList.push({ ...i, favorite: true });
          } else {
            newList.push(i);
          }
        }
      });
      return newList;
    }
    return [];
  }, [countries]);

  const sortCountries = useMemo(() => {
    if (intl.locale === 'ru') {
      return filterCountries && filterCountries.sort((a, b) => a.ru_name.localeCompare(b.ru_name));
    }
    return filterCountries && filterCountries.sort((a, b) => a.en_name.localeCompare(b.en_name));
  }, [filterCountries, intl]);

  return (
    <Autocomplete
      id='country-select-demo'
      options={sortCountries}
      autoHighlight
      disabled={disabled}
      style={{ marginTop: 16, minWidth: 200 }}
      value={filterCountries.find(i => i.id === countryId) || defaultCountry}
      loading={countriesLoading}
      onChange={(_event: any, newValue) => {
        newValue && setCountryId(newValue.id);
      }}
      noOptionsText={intl.formatMessage({ id: 'CATEGORIES.TABLE.EMPTY' })}
      loadingText={intl.formatMessage({ id: 'COMMON.LOADING' })}
      disableClearable
      getOptionLabel={option =>
        `${intl.locale === 'ru' ? option.ru_name : option.en_name} ${
          showCode ? `+${option.code}` : ''
        }`
      }
      renderOption={option => (
        <Box component='li' style={{ listStyle: 'none' }}>
          {intl.locale === 'ru' ? option.ru_name : option.en_name}
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label={label || intl.formatMessage({ id: 'AUTH.INPUT.COUNTRIES' })}
          variant={variant}
          inputProps={{
            ...params.inputProps,
            style: { fontFamily: 'Open Sans', cursor: 'pointer' },
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default CountrySelect;
