/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import { withRouter } from 'react-router-dom';

import BreadCrumbs from './components/BreadCrumbs';
import { LayoutContextConsumer } from '../LayoutContext';
import * as builder from '../../ducks/builder';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ButtonAddProduct from '../../../app/components/ui/Buttons/ButtonAddProduct';
import ButtonAddTender from '../../../app/components/ui/Buttons/ButtonAddTender';
import { UserRoles } from '../../../app/interfaces/user';
import { ViewMode } from '../../../app/pages/home/orders/OrderPage';
import SearchField from '../../../app/components/ui/SearchField';

// import { QuickActions } from './components/QuickActions';
// import { ReactComponent as SortNum1Icon } from '../../../_metronic/layout/assets/layout-svg-icons/SortNum1.svg';

class SubHeader extends React.Component {
  render() {
    const {
      subheaderCssClasses,
      subheaderContainerCssClasses,
      subheaderMobileToggle,
      userRoles,
      me,
    } = this.props;
    const history = this.props.history;
    const newMode = ViewMode.VIEW;

    return (
      <div id='kt_subheader' className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}>
        <div className={`kt-container ${subheaderContainerCssClasses}`}>
          <div className='kt-subheader__main'>
            {subheaderMobileToggle && (
              <button
                className='kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left'
                id='kt_subheader_mobile_toggle'
              >
                <span />
              </button>
            )}

            <LayoutContextConsumer>
              {/*{({ subheader: { title, breadcrumb } }) => (*/}

              {({
                subheader: {
                  title,
                  breadcrumb,
                  description,
                  back,
                  rightTitle,
                  onPress,
                  orderCounts,
                  subTitle,
                  orders,
                  items,
                  orderTitle,
                  ordersId,
                },
              }) => (
                <>
                  {subTitle && (
                    <div
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    >
                      <h3
                        className='kt-subheader__title'
                        style={{ fontWeight: 600, paddingRight: 5 }}
                      >
                        {subTitle}
                      </h3>

                      {items.main_order && (
                        <h3
                          className='kt-subheader__title'
                          style={{ fontWeight: 600, paddingLeft: 0, paddingRight: 5 }}
                        >
                          {items.id} в
                        </h3>
                      )}

                      {items.main_order && (
                        <h3
                          onClick={() =>
                            history.push(`/orders/${items.main_order.id}/${newMode}`)
                          }
                          className='kt-subheader__title'
                          style={{
                            fontWeight: 600,
                            paddingLeft: 0,
                            paddingRight: 5,
                            color: '#d01c1f',
                            cursor: 'pointer',
                          }}
                        >
                          заказе № {items.main_order.id}
                        </h3>
                      )}

                      <h3
                        className='kt-subheader__title'
                        style={{ fontWeight: 600, paddingRight: 5 }}
                      >
                        {orderTitle}
                      </h3>
                    </div>
                  )}

                  <div className='kt-subheader__wrap-left'>
                    {back && (
                      <IconButton
                        color='inherit'
                        aria-label='previous'
                        onClick={() => this.props.history.goBack()}
                      >
                        <ChevronLeftIcon color='inherit' />
                      </IconButton>
                    )}
                    {breadcrumb && <BreadCrumbs items={breadcrumb} />}
                    {title && description && (
                      <span className='kt-subheader__separator kt-subheader__separator--v' />
                    )}
                    {title &&
                      (rightTitle ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <h3
                              className='kt-subheader__title'
                              style={{ fontWeight: 600, paddingRight: 5 }}
                            >
                              {title}
                            </h3>
                            {orders &&
                              orders.length > 0 &&
                              orders.map(i => (
                                <h3
                                  onClick={() => history.push(`/orders/${i.id}/${newMode}`)}
                                  key={i.id}
                                  className='kt-subheader__title'
                                  style={{
                                    fontWeight: 600,
                                    color: '#d01c1f',
                                    paddingLeft: 0,
                                    paddingRight: 5,
                                    cursor: 'pointer',
                                  }}
                                >
                                  {i.id},
                                </h3>
                              ))}
                            {ordersId &&
                              ordersId.map(i => (
                                <h3
                                  onClick={() => history.push(`/orders/${i.id}/${newMode}`)}
                                  key={i.id}
                                  className='kt-subheader__title'
                                  style={{
                                    fontWeight: 600,
                                    color: '#d01c1f',
                                    paddingLeft: 0,
                                    paddingRight: 5,
                                    cursor: 'pointer',
                                  }}
                                >
                                  {i.id},
                                </h3>
                              ))}
                          </div>
                          {!subTitle && (
                            <h3
                              onClick={onPress}
                              className='kt-subheader__title'
                              style={{ fontWeight: 600, color: '#d01c1f', cursor: 'pointer' }}
                            >
                              {rightTitle}
                            </h3>
                          )}
                        </div>
                      ) : (
                        <h3 className='kt-subheader__title' style={{ fontWeight: 600 }}>
                          {title}
                        </h3>
                      ))}
                    {this.props.location.pathname === '/products/catalog' && (
                      <ButtonAddProduct />
                    )}
                    {me &&
                      !me.roles.includes(UserRoles.ROLE_VENDOR) &&
                      this.props.location.pathname === '/product_search/list' && (
                        <ButtonAddTender />
                      )}
                    <div
                      style={{
                        display: 'flex',
                        flexGrow: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      <div>
                        {!me ||
                          (!me?.roles.includes(UserRoles.ROLE_BUYER) &&
                            this.props.location.pathname === '/products/catalog' && (
                              <SearchField isSubHeader={false} />
                            ))}
                      </div>
                    </div>
                  </div>
                  {/* {userRoles?.[0] !== 'ROLE_VENDOR' && (
                    <Link to={`/cart`} className="kt-menu__link ">
                      <i className={`kt-menu__link-icon flaticon-cart`} />
                      <span
                        className="kt-menu__link-text"
                        style={{ paddingLeft: 10, fontWeight: 400 }}
                      >
                        <FormattedMessage
                          id="CART.SUBHEADER.TITLE"
                          defaultMessage={'CART.SUBHEADER.TITLE'}
                        />
                      </span>
                    </Link>
                  )} */}

                  {description && <span className='kt-subheader__desc'>{description}</span>}

                  {/* {!me ||
                    (!me?.roles.includes(UserRoles.ROLE_BUYER) &&
                      this.props.location.pathname === '/products/catalog' && (
                        <SearchField isSubHeader={true} />
                      ))} */}
                </>
              )}
            </LayoutContextConsumer>

            {/* <a href="#" className="btn btn-label-warning btn-bold btn-sm btn-icon-h kt-margin-l-10">
              Add New
            </a> */}
          </div>

          {/* <div className="kt-subheader__toolbar">
            <div className="kt-subheader__wrapper">
              <button type="button" className="btn kt-subheader__btn-primary">
                Actions &nbsp;
                <SortNum1Icon className="kt-svg-icon kt-svg-icon--sm" />
              </button>
              <QuickActions />
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  me: store.profile.me,
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  userRoles: store.auth.user?.roles,
  subheaderMobileToggle: objectPath.get(store.builder.layoutConfig, 'subheader.mobile-toggle'),
  subheaderCssClasses: builder.selectors.getClasses(store, {
    path: 'subheader',
    toString: true,
  }),
  subheaderContainerCssClasses: builder.selectors.getClasses(store, {
    path: 'subheader_container',
    toString: true,
  }),
});

export default withRouter(connect(mapStateToProps)(SubHeader));
