import * as React from 'react';
import { ProductCatalogList } from '../components/Menu';

export const menuConfigItems = {
  profile: {
    root: true,
    title: 'SUBMENU.PROFILE',
    translate: 'SUBMENU.PROFILE',
    page: 'settings/profile',
    activePages: ['settings/profile'],
  },

  logout: {
    root: true,
    title: 'MENU.LOGOUT',
    translate: 'MENU.LOGOUT',
    page: 'logout',
    // icon: 'flaticon-logout',
  },

  products: {
    title: 'MENU.PRODUCTS',
    translate: 'MENU.PRODUCTS',
    bullet: 'dot',
    root: true,
    activePages: ['products', 'categories', 'viewproduct'],
    inActivePages: ['products/types'],
    page: 'products/catalog',
    submenu: [
      {
        title: 'PRODUCT.ALL.PRODUCTS',
        translate: 'PRODUCT.ALL.PRODUCTS',
        page: 'products/catalog',
        activePages: ['products/catalog'],
        dynamic: () => <ProductCatalogList />,
      },
      // {
      //   title: 'PRODUCT.ALL.NEW_PRODUCTS',
      //   page: 'products/new',
      //   translate: 'PRODUCT.ALL.NEW_PRODUCTS',
      // },
      // {
      //   title: 'MENU.COMPANY.PRODUCTS',
      //   page: 'products/list',
      //   translate: 'MENU.COMPANY.PRODUCTS',
      //   activePages: ['products/edit', 'products/create'],
      // },
      // {
      //   title: 'CATEGORIES.TABLE.TOOLTIP.FAVORITES_PRODUCTS',
      //   page: 'products/favorites',
      //   translate: 'CATEGORIES.TABLE.TOOLTIP.FAVORITES_PRODUCTS',
      // },
      {
        title: 'MENU.CATALOG.CATEGORIES.LIST',
        page: 'categories/list',
        translate: 'MENU.CATALOG.CATEGORIES.LIST',
        activePages: ['categories/edit', 'categories/new'],
      },
      {
        title: 'MENU.CATALOG.CATEGORIES.TREE',
        page: 'categories/tree',
        translate: 'MENU.CATALOG.CATEGORIES.TREE',
      },
    ],
  },

  vendorProducts: {
    title: 'MENU.PRODUCTS',
    translate: 'MENU.PRODUCTS',
    bullet: 'dot',
    root: true,
    activePages: ['products', 'categories', 'viewproduct'],
    submenu: [
      // {
      //   title: 'MENU.CATALOG.PRODUCTS',
      //   translate: 'MENU.CATALOG.PRODUCTS',
      //   page: 'products/catalog',
      //   activePages: ['products/catalog'],
      //   dynamic: () => <ProductCatalogList />,
      // },
      {
        title: 'MENU.COMPANY.PRODUCTS',
        page: 'products/company/list',
        translate: 'MENU.COMPANY.PRODUCTS',
        activePages: ['products/edit', 'products/create'],
      },
      // {
      //   title: 'CATEGORIES.TABLE.TOOLTIP.FAVORITES_PRODUCTS',
      //   page: 'products/favorites',
      //   translate: 'CATEGORIES.TABLE.TOOLTIP.FAVORITES_PRODUCTS',
      // },
      {
        title: 'MENU.CATALOG.CATEGORIES.LIST',
        page: 'categories/list',
        translate: 'MENU.CATALOG.CATEGORIES.LIST',
        activePages: ['categories/edit', 'categories/new'],
      },
      {
        title: 'MENU.CATALOG.CATEGORIES.TREE',
        page: 'categories/tree',
        translate: 'MENU.CATALOG.CATEGORIES.TREE',
      },
    ],
  },

  vendorCompany: {
    root: true,
    title: 'MENU.VENDOR.COMPANY',
    translate: 'MENU.VENDOR.COMPANY',
    bullet: 'dot',
    activePages: ['companies', 'stores'],
    submenu: [
      {
        translate: 'SUBMENU.COMPANY_SETTINGS',
        title: 'SUBMENU.COMPANY_SETTINGS',
        page: 'companies/list',
        activePages: ['companies/edit', 'companies/new'],
      },
      {
        translate: 'MENU.CATALOG.STORES',
        title: 'MENU.CATALOG.STORES',
        page: 'stores/list',
        activePages: ['stores/edit', 'stores/create'],
      },
    ],
  },

  buyerCompany: {
    root: true,
    title: 'MENU.BUYER.COMPANY',
    translate: 'MENU.BUYER.COMPANY',
    bullet: 'dot',
    page: 'companies/list',
    activePages: ['companies/edit', 'companies/new'],
  },

  orders: {
    root: true,
    title: 'MENU.ORDERS',
    translate: 'MENU.ORDERS',
    bullet: 'dot',
    activePages: ['orders/'],
    page: 'orders/list/full',
    // submenu: [
    //   {
    //     translate: 'SUBMENU.ALLORDERS',
    //     title: 'SUBMENU.ALLORDERS',
    //     page: 'orders/list/full',
    //   },
    //   {
    //     translate: 'SUBMENU.ABANDONEDCHECKOUTS',
    //     title: 'SUBMENU.ABANDONEDCHECKOUTS',
    //     page: 'orders/list/abandoned',
    //   },
    // ],
  },

  tenders: {
    root: true,
    title: 'MENU.TENDERS',
    translate: 'MENU.TENDERS',
    bullet: 'dot',
    page: 'product_search/list',
    activePages: ['product_search'],
  },

  buyersOrders: {
    root: true,
    title: 'MENU.MY_ORDERS',
    translate: 'MENU.MY_ORDERS',
    bullet: 'dot',
    page: 'orders/list/full',
    activePages: ['orders'],
  },

  companies: {
    root: true,
    title: 'MENU.COMPANIES',
    translate: 'MENU.COMPANIES',
    bullet: 'dot',
    activePages: ['companies', 'stores', 'viewcompany'],
    page: 'companies/list',
    submenu: [
      {
        translate: 'MENU.CATALOG.STORES',
        title: 'MENU.CATALOG.STORES',
        page: 'stores/list',
      },
    ],
  },

  users: {
    root: true,
    title: 'MENU.USERS',
    translate: 'MENU.USERS',
    bullet: 'dot',
    activePages: ['users'],
    page: 'users/vendors',
    submenu: [
      {
        translate: 'SUBMENU.VENDORS',
        title: 'SUBMENU.VENDORS',
        page: 'users/vendors',
      },
      {
        translate: 'SUBMENU.CUSTOMERS',
        title: 'SUBMENU.CUSTOMERS',
        page: 'users/buyers',
      },
      // {
      //   translate: 'SUBMENU.TEAM',
      //   title: 'SUBMENU.TEAM',
      //   page: 'users/managers',
      // },
      {
        translate: 'SUBMENU.COMPANIES',
        title: 'SUBMENU.COMPANIES',
        page: 'companies/list',
      },
    ],
  },

  vendorUsers: {
    root: true,
    title: 'MENU.TEAM',
    translate: 'MENU.TEAM',
    bullet: 'dot',
    page: 'users/managers',
    activePages: ['users'],
  },

  settings: {
    root: true,
    title: 'MENU.SETTINGS',
    bullet: 'dot',
    translate: 'MENU.SETTINGS',
    activePages: ['products/types'],
    page: 'products/types/list',
    submenu: [
      {
        title: 'MENU.PRODUCTS.TYPES',
        translate: 'MENU.PRODUCTS.TYPES',
        page: 'products/types/list',
        activePages: ['products/types/create'],
      },
      // {
      //   title: 'COLLECTIONS',
      //   translate: 'COLLECTIONS',
      //   page: 'collections/list',
      //   activePages: ['collections/list'],
      // },
      // {
      //   title: 'MENU.PRODUCTS.PROMOCODES',
      //   translate: 'MENU.PRODUCTS.PROMOCODES',
      //   page: 'promocodes/list',
      //   activePages: ['promocodes', 'promocodes/edit', 'promocodes/new'],
      // },
      // {
      //   title: 'MENU.PRODUCTS.REFFERALS',
      //   translate: 'MENU.PRODUCTS.REFFERALS',
      //   page: 'refferals/list',
      //   activePages: ['refferals', 'refferals/edit', 'refferals/new'],
      // },
      // {
      //   title: 'MENU.PRODUCTS.TRANSACTIONS',
      //   translate: 'MENU.PRODUCTS.TRANSACTIONS',
      //   page: 'transactions/list',
      //   activePages: ['transactions'],
      // },
    ],
  },

  legal: {
    root: true,
    title: 'SUBMENU.LEGAL',
    translate: 'SUBMENU.LEGAL',
    bullet: 'dot',
    activePages: ['documents'],
    page: 'documents/legacy',
    submenu: [
      {
        title: 'SUBMENU.LEGAL_TERMS',
        translate: 'SUBMENU.LEGAL_TERMS',
        page: 'documents/legacy',
      },
      {
        title: 'SUBMENU.LEGAL_PRIVACY',
        translate: 'SUBMENU.LEGAL_PRIVACY',
        page: 'documents/privacy',
      },
      {
        title: 'SUBMENU.FOR_VENDOR',
        translate: 'SUBMENU.FOR_VENDOR',
        page: 'documents/vendor',
      },
      {
        title: 'SUBMENU.FOR_CUSTOMER',
        translate: 'SUBMENU.FOR_CUSTOMER',
        page: 'documents/customer',
      },
      // {
      //   title: 'SUBMENU.LEGAL_SHIPPING',
      //   translate: 'SUBMENU.LEGAL_SHIPPING',
      //   page: 'documents/shipping',
      // },
      // {
      //   title: 'SUBMENU.LEGAL_REFUND',
      //   translate: 'SUBMENU.LEGAL_REFUND',
      //   page: 'documents/refund',
      // },
    ],
  },

  catalog: {
    root: true,
    title: 'MENU.CATALOG.PRODUCTS',
    translate: 'MENU.CATALOG.PRODUCTS',
    bullet: 'dot',
    // icon: 'flaticon-book',
    page: 'products/catalog',
    dynamic: () => <ProductCatalogList />,
    activePages: ['products', 'viewproduct'],
    submenu: [
      {
        title: 'MENU.CATALOG.PRODUCTS',
        translate: 'MENU.CATALOG.PRODUCTS',
        page: 'products/catalog',
        activePages: ['products/catalog'],
        dynamic: () => <ProductCatalogList />,
      },
      {
        title: 'COLLECTIONS',
        translate: 'COLLECTIONS',
        page: 'collections/list',
        activePages: ['collections/list'],
      },
    ],
  },

  catalogForUser: {
    root: true,
    title: 'MENU.CATALOG.PRODUCTS',
    translate: 'MENU.CATALOG.PRODUCTS',
    bullet: 'dot',
    // icon: 'flaticon-book',
    page: 'products/catalog',
    dynamic: () => <ProductCatalogList />,
    activePages: ['products', 'viewproduct'],
    // submenu: [
    //   {
    //     title: 'MENU.CATALOG.PRODUCTS',
    //     translate: 'MENU.CATALOG.PRODUCTS',
    //     page: 'products/catalog',
    //     activePages: ['products/catalog'],
    //     dynamic: () => <ProductCatalogList />,
    //   },
    //   {
    //     title: 'CATEGORIES.TABLE.TOOLTIP.FAVORITES_PRODUCTS',
    //     page: 'products/favorites',
    //     translate: 'CATEGORIES.TABLE.TOOLTIP.FAVORITES_PRODUCTS',
    //   },
    //   {
    //     title: 'COLLECTIONS',
    //     translate: 'COLLECTIONS',
    //     page: 'collections/list',
    //     activePages: ['collections/list'],
    //   },
    // ],
  },

  login: {
    root: true,
    title: 'MENU.LOGIN',
    translate: 'MENU.LOGIN',
    bullet: 'dot',
    // icon: 'flaticon-login',
    page: 'auth',
  },

  blog: {
    root: true,
    title: 'SUBMENU.BLOG',
    translate: 'SUBMENU.BLOG',
    bullet: 'dot',
    activePages: ['blog'],
    page: 'blog/my',
    submenu: [
      {
        title: 'SUBMENU.BLOG.ALL',
        page: 'blog/all',
        translate: 'SUBMENU.BLOG.ALL',
        activePages: ['blog/edit', 'blog/create', 'blog/view'],
      },
      {
        title: 'SUBMENU.BLOG.MY',
        page: 'blog/my',
        translate: 'SUBMENU.BLOG.MY',
      },
    ],
  },

  guestBlog: {
    root: true,
    title: 'SUBMENU.BLOG.ALL',
    translate: 'SUBMENU.BLOG.ALL',
    bullet: 'dot',
    page: 'blog/all',
    activePages: ['blog'],
  },

  tariffs: {
    root: true,
    title: 'SUBMENU.TARIFFS',
    translate: 'SUBMENU.TARIFFS',
    bullet: 'dot',
    activePages: ['tariffs'],
    page: 'tariffs/list',
    // submenu: [
    //   {
    //     title: 'SUBMENU.TARIFFS.BUYERS',
    //     page: 'tariffs/list/buyers',
    //     translate: 'SUBMENU.TARIFFS.BUYERS',
    //   },
    //   {
    //     title: 'SUBMENU.TARIFFS.VENDORS',
    //     page: 'tariffs/list/vendors',
    //     translate: 'SUBMENU.TARIFFS.VENDORS',
    //   },
    // ],
  },

  buyersTariffs: {
    root: true,
    title: 'SUBMENU.TARIFFS',
    translate: 'SUBMENU.TARIFFS',
    bullet: 'dot',
    page: 'tariffs/list/buyers',
    activePages: ['tariffs'],
  },

  vendorsTariffs: {
    root: true,
    title: 'SUBMENU.TARIFFS',
    translate: 'SUBMENU.TARIFFS',
    bullet: 'dot',
    page: 'tariffs/list/vendors',
    activePages: ['tariffs'],
  },

  chats: {
    root: true,
    title: 'SUBMENU.CHATS',
    translate: 'SUBMENU.CHATS',
    bullet: 'dot',
    page: 'chats',
    activePages: ['chats'],
  },
};
