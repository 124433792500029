import React, { useEffect, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import {
  createStyles,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import InputMask from 'react-input-mask';

import ButtonWithLoader from '../../../../components/other/Buttons/ButtonWithLoader';
import { IAppState } from '../../../../store/rootDuck';
import { actions as profileActions } from '../../../../store/ducks/profile.duck';
import { actions as i18nActions } from '../../../../../_metronic/ducks/i18n';
import useCrudSnackbar from '../../../../hooks/useCrudSnackbar';
import { IUser, IUserEditProps, TUserAttributes } from '../../../../interfaces/user';
import homeStyles from '../../../../constants/homeStyles';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { cleanPhone } from '../../../../utils/utils';
import { useHandleCountrySelect } from '../../../auth/hooks/useHandleCountrySelect';
import { useAxiosGetCallback } from '../../../../hooks/useAxiosGet';
import CountrySelect from "../../../../components/ui/CountrySelect";

interface IProps {
  me: IUser;
  userAttr: any;
}

export const languages = [
  {
    lang: 'ru',
    name: 'Русский',
    flag: toAbsoluteUrl('/media/flags/248-russia.svg'),
  },
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/260-united-kingdom.svg'),
  },
];

const ProfileForm: React.FC<TPropsFromRedux & IProps> = ({
  me,
  clearEdit,
  edit,
  editSuccess,
  editLoading,
  editError,
  setLanguage,
}) => {
  const classes = useStyles();
  const homeClasses = homeStyles();
  const intl = useIntl();
  const [countries, countriesLoading, countriesErr, makeRequest] = useAxiosGetCallback<any>();
  const { countryId, setCountryId, countryData } = useHandleCountrySelect(countries, 1);
  const isBuyer = me?.roles[0] === 'ROLE_BUYER';
  const disabledPhone = useMemo(() => {
    if (me.is_admin) {
      return false;
    }
    if (!me.is_admin && !me.phone_numbers) {
      return false;
    }
    return true;
  }, [me]);

  useEffect(() => {
    makeRequest('/api/phone_codes');
  }, []);

  useEffect(() => {
    if (me?.country?.id) {
      setCountryId(me.country.id);
    }
  }, [me]);

  const getUserAttrCountry = (attributes: TUserAttributes) => {
    const countries = attributes.filter(el => el.type === 'country');
    return countries[0]?.value || '';
  };

  const getUserAttrLanguages = (attributes: TUserAttributes) => {
    return attributes.filter(el => el.type === 'languages').map(el => el.value);
  };

  useCrudSnackbar({
    success: editSuccess,
    error: editError,
    clear: clearEdit,
    successMessage: intl.formatMessage({ id: 'PROFILE.EDIT.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${editError}`,
    afterSuccessOrError: () => {
      clearEdit();
    },
  });

  return (
    <Formik
      initialValues={{
        login: me ? me.login : '',
        email: me ? me.email : '',
        password: '',
        password2: '',
        role: me?.roles.length > 0 ? me.roles[0] : '',
        locale: me ? me.locale : 'en',
        country: me?.attributes ? getUserAttrCountry(me.attributes) : '',
        languages: me?.attributes ? getUserAttrLanguages(me.attributes) : [],
        phoneNumbers: me?.phone_numbers
          ? cleanPhone(me.phone_numbers, `+${countryData?.code || '7'}`)
          : '',
        first_name: me.first_name || '',
        last_name: me.last_name || '',
        middle_name: me.middle_name || '',
      }}
      validationSchema={Yup.object().shape({
        login: Yup.string()
          .required(intl.formatMessage({ id: 'PROFILE.VALIDATION.REQUIRED_LOGIN' }))
          .nullable(),
        email: Yup.string()
          .required(intl.formatMessage({ id: 'PROFILE.VALIDATION.REQUIRED_EMAIL' }))
          .email(intl.formatMessage({ id: 'PROFILE.VALIDATION.EMAIL' }))
          .nullable(),
        password2: Yup.string().oneOf(
          [Yup.ref('password'), ''],
          intl.formatMessage({ id: 'PROFILE.VALIDATION.PASSWORD_COMPARE' })
        ),
        role: Yup.string().required(
          intl.formatMessage({ id: 'PROFILE.VALIDATION.REQUIRED_ROLE' })
        ),
      })}
      onSubmit={values => {
        const body: IUserEditProps = {
          login: values.login,
          email: values.email,
          role: values.role,
          countryId: countryData.id,
          phoneNumbers: values.phoneNumbers,
          country: intl.locale === 'ru' ? countryData.ru_name : countryData.en_name,
          first_name: values.first_name,
          last_name: values.last_name,
          middle_name: values.middle_name,
          countryCode: countryData.code || '7',
          attributes: [
            {
              type: 'languages',
              // @ts-ignore
              value: values.languages,
            },
            {
              type: 'country',
              value: values.country,
            },
          ],
        };

        if (values.password) {
          body.password = values.password;
        }

        if (values.locale) {
          setLanguage(values.locale);
          body.locale = values.locale;
        }

        edit(body);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => {
        let userRole: string;
        if (values?.role === 'ROLE_ADMIN') {
          userRole = intl.formatMessage({ id: 'USER.ROLES.ADMIN' });
        } else if (values.role === 'ROLE_VENDOR') {
          userRole = intl.formatMessage({ id: 'USER.ROLES.VENDOR' });
        } else {
          userRole = intl.formatMessage({ id: 'USER.ROLES.BUYER' });
        }

        return (
          <form className={homeClasses.form} onSubmit={handleSubmit}>
            <TextField
              type='text'
              label={intl.formatMessage({ id: 'USER.EDIT.LOGIN' })}
              margin='normal'
              name='login'
              value={values.login || ''}
              variant='outlined'
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.login && errors.login}
              error={Boolean(touched.login && errors.login)}
            />

            <TextField
              type='text'
              label={intl.formatMessage({ id: 'USER.EDIT.EMAIL' })}
              margin='normal'
              name='email'
              value={values.email || ''}
              variant='outlined'
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.email && errors.email}
              error={Boolean(touched.email && errors.email)}
            />
            <CountrySelect
                countries={countries}
                countryId={countryId}
                setCountryId={setCountryId}
                countriesLoading={countriesLoading}
                variant='standard'
            />
            {countryData && (
              <InputMask
                mask={countryData.mask}
                onBlur={handleBlur}
                value={values.phoneNumbers}
                alwaysShowMask
                disabled={disabledPhone}
                onChange={(e: any) => {
                  setFieldValue(
                    'phoneNumbers',
                    e.target.value ? e.target.value.replace(/[^0-9]/g, '') : ''
                  );
                }}
              >
                {(inputProps: any) => (
                  <TextField
                    {...inputProps}
                    type='tel'
                    label={intl.formatMessage({ id: 'AUTH.INPUT.PHONE' })}
                    margin='normal'
                    className='kt-width-full'
                    name='phoneNumbers'
                    helperText={touched.phoneNumbers && errors.phoneNumbers}
                    error={Boolean(touched.phoneNumbers && errors.phoneNumbers)}
                    onBlur={handleBlur}
                    variant='outlined'
                    disabled={disabledPhone}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment style={{fontSize: 17}} position='start'> +{countryData.code} </InputAdornment>
                      ),
                    }}
                  />
                )}
              </InputMask>
            )}

            <TextField
              autoComplete='off'
              type='password'
              label={intl.formatMessage({ id: 'USER.EDIT.PASSWORD' })}
              margin='normal'
              name='password'
              value={values.password || ''}
              variant='outlined'
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.password && errors.password}
              error={Boolean(touched.password && errors.password)}
              className='kt-width-full'
            />

            <TextField
              autoComplete='off'
              type='password'
              margin='normal'
              label={intl.formatMessage({ id: 'USER.EDIT.PASSWORD.REPEAT' })}
              name='password2'
              value={values.password2 || ''}
              variant='outlined'
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.password2 && errors.password2}
              error={Boolean(touched.password2 && errors.password2)}
            />

            <TextField
              // select
              type='text'
              margin='normal'
              label={intl.formatMessage({ id: 'USER.EDIT.ROLE' })}
              value={userRole}
              onChange={handleChange}
              name='role'
              variant='outlined'
              helperText={touched.role && errors.role}
              error={Boolean(touched.role && errors.role)}
              disabled
            >
              {/* <MenuItem value=''>{intl.formatMessage({ id: 'COMMON.NOT_SELECTED' })}</MenuItem>
            <MenuItem value='ROLE_ADMIN'>
              {intl.formatMessage({ id: 'USER.ROLES.ADMIN' })}
            </MenuItem>
            <MenuItem value='ROLE_VENDOR'>
              {intl.formatMessage({ id: 'USER.ROLES.VENDOR' })}
            </MenuItem>
            <MenuItem value='ROLE_MANAGER'>
              {intl.formatMessage({ id: 'USER.ROLES.MANAGER' })}
            </MenuItem> */}
            </TextField>

            <TextField
              type='text'
              label={intl.formatMessage({ id: 'USER.EDIT.LAST_NAME' })}
              margin='normal'
              name='last_name'
              value={values.last_name || ''}
              variant='outlined'
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.last_name && errors.last_name}
              error={Boolean(touched.last_name && errors.last_name)}
            />

            <TextField
              type='text'
              label={intl.formatMessage({ id: 'USER.EDIT.FIRST_NAME' })}
              margin='normal'
              name='first_name'
              value={values.first_name || ''}
              variant='outlined'
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.first_name && errors.first_name}
              error={Boolean(touched.first_name && errors.first_name)}
            />

            <TextField
              type='text'
              label={intl.formatMessage({ id: 'USER.EDIT.MIDDLE_NAME' })}
              margin='normal'
              name='middle_name'
              value={values.middle_name || ''}
              variant='outlined'
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={touched.middle_name && errors.middle_name}
              error={Boolean(touched.middle_name && errors.middle_name)}
            />

            <TextField
              select
              margin='normal'
              label={intl.formatMessage({ id: 'AUTH.INPUT.LANG' })}
              value={values.locale || ''}
              onChange={handleChange}
              name='locale'
              variant='outlined'
            >
              {languages.map(lang => (
                <MenuItem key={lang.lang} value={lang.lang}>
                  <div className={classes.lang}>
                    <span className={classes.langImage}>
                      <img src={lang.flag} alt={lang.name} />
                    </span>
                    <span className={classes.langTitle}>{lang.name}</span>
                  </div>
                </MenuItem>
              ))}
            </TextField>

            <div className={classes.actions}>
              <ButtonWithLoader disabled={editLoading} loading={editLoading}>
                {intl.formatMessage({ id: 'COMMON.BUTTON.SAVE' })}
              </ButtonWithLoader>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

const connector = connect(
  (state: IAppState) => ({
    editSuccess: state.profile.editSuccess,
    editLoading: state.profile.editLoading,
    editError: state.profile.editError,
  }),
  {
    clearEdit: profileActions.clearEdit,
    edit: profileActions.editRequest,
    setLanguage: i18nActions.setLanguage,
  }
);

const useStyles = makeStyles(theme =>
  createStyles({
    actions: {
      marginTop: theme.spacing(3),
    },
    lang: {
      display: 'flex',
      alignItems: 'center',
    },
    langImage: {
      width: '30px',
      height: '30px',
      borderRadius: '5px',
      overflow: 'hidden',
    },
    langTitle: {
      marginLeft: '5px',
    },
  })
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProfileForm);
